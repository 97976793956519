body {
  background-color: rgb(22, 26, 30);
}

#root {
  box-sizing: border-box;
  padding: 18px;
}

.ticker-container {
  border: 1px solid rgb(183, 189, 198);
  width: 298px;
  box-sizing: border-box;
  padding: 4px;
  display: inline-block;
  vertical-align: top;
}

.ticker-title {
  font-size: 20px;
  font-weight: 500;
  color: rgb(183, 189, 198);
  text-align: center;
  padding: 8px;
}

.bids {
  padding-bottom: 6px;
}

.asks {
  width: 298px;
  box-sizing: border-box;
}

.asksPrice, .asksAmount, .asksTotal, .itemAsksPrice, .itemAsksAmount, .itemAsksTotal, .itemBidsPrice, .itemBidsAmount, .itemBidsTotal {
  display: inline-block;
  font-size: 10px;
  color: rgb(183, 189, 198);
  width: 96px;
  text-align: center;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.itemAsksPrice {
  color: rgb(246, 70, 93);
}

.itemBidsPrice {
  color: rgb(14, 203, 129);
}

.price {
  font-size: 20px;
  -webkit-box-align: center;
  align-items: center;
  margin: 18px;
  text-align: center;
  color: rgb(183, 189, 198);
  display: block;
  text-align: center;
}

.calculations {
  border-top: 1px solid rgb(183, 189, 198);
  box-sizing: border-box;
}

.calculations-quantity, .calculations-quantity-title {
  display: inline-block;
  color: rgb(183, 189, 198);
  font-size: 12px;
  width: 124px;
}

.calculations-quantity-title {
  text-align: left;
  padding-left: 26px;
}

.calculations-quantity {
  text-align: right;
}

.overT {
  color: rgb(14, 203, 129);
  font-size: 16px;
  padding: 8px;
  font-weight: 900;
}

.amountInfoContainer {
  display: inline-block;
  margin: 20px;
}

.amountInfo {
  color: rgb(183, 189, 198);
  font-size: 16px;
  padding: 8px;
}

.amountInfoGreen {
  color: rgb(14, 203, 129);
  font-weight: 900;
}

.amountInfoRed {
  color: rgb(246, 70, 93);
  font-weight: 900;
}

.amountInfoBold {
  font-weight: 900;
}